"use client";

import { ReactElement, useMemo } from "react";
import { GraphQLClient } from "graphql-request";

import GraphQLRequestContext from "./GraphQLRequestContext";

const GraphQLRequestProvider = ({
  children,
  graphqlUrl,
  accessToken,
}: {
  children: ReactElement;
  graphqlUrl: string;
  accessToken?: string;
}) => {
  const value = useMemo(
    () => ({
      graphQLRequestClient: new GraphQLClient(graphqlUrl),
      authenticatedGraphQLRequestClient: accessToken
        ? new GraphQLClient(graphqlUrl, {
            headers: {
              authorization: `Bearer ${accessToken}`,
            },
          })
        : undefined,
    }),
    [graphqlUrl, accessToken]
  );

  return (
    <GraphQLRequestContext.Provider value={value}>
      {children}
    </GraphQLRequestContext.Provider>
  );
};

export default GraphQLRequestProvider;
