import { MessageInstance } from "antd/es/message/interface";

import { GraphqlErrorType } from "./GraphqlErrorType";

export default async (
  errors: GraphqlErrorType[],
  messageApi: MessageInstance
) => {
  errors.forEach((error) => {
    if (error.spanishError) messageApi.error(error.spanishError);
    else messageApi.error(error.message);
  });
};
