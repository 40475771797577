"use client";

import { ReactNode, useMemo } from "react";
import { Modal } from "antd";

import ModalContext from "./ModalContext";
import useModal from "./useModal";

const ModalChild = ({ children }: { children: ReactNode }) => {
  const { contextHolder } = useModal();

  return (
    <>
      {contextHolder}
      {children}
    </>
  );
};

const ModalProvider = ({ children }: { children: ReactNode }) => {
  const [modal, contextHolder] = Modal.useModal();

  const value = useMemo(
    () => ({
      modalApi: modal,
      contextHolder,
    }),
    [modal, contextHolder]
  );

  return (
    <ModalContext.Provider value={value}>
      <ModalChild>{children}</ModalChild>
    </ModalContext.Provider>
  );
};

export default ModalProvider;
