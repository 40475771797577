"use client";

import { createContext } from "react";
import { GraphQLClient } from "graphql-request";

interface IGraphQLContext {
  graphQLRequestClient: GraphQLClient;
  authenticatedGraphQLRequestClient?: GraphQLClient;
}

const GraphQLRequestContext = createContext<IGraphQLContext>({
  graphQLRequestClient: {} as GraphQLClient,
});

export default GraphQLRequestContext;
