"use client";

import { createContext, ReactNode } from "react";
import { HookAPI } from "antd/es/modal/useModal";

interface IModalContext {
  modalApi: HookAPI;
  contextHolder: ReactNode;
}

const ModalContext = createContext<IModalContext>({
  modalApi: {} as HookAPI,
  contextHolder: null,
});

export default ModalContext;
