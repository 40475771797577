"use client";

import { ReactElement, useEffect, useMemo, useState } from "react";
import {
  ApolloClient,
  createHttpLink,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";

import { useAuth } from "../Auth";

import GraphQLContext from "./GraphQLContext";

const GraphQLProvider = ({
  children,
  graphqlUrl,
}: {
  children: ReactElement;
  graphqlUrl: string;
}) => {
  const { accessToken, identityClaims } = useAuth();

  const graphQLClient = useMemo(
    () =>
      new ApolloClient({
        link: new HttpLink({ uri: `${graphqlUrl}` }),
        cache: new InMemoryCache(),
      }),
    [graphqlUrl]
  );

  const [authenticatedGraphQLClient, setAuthenticatedGraphQLClient] =
    useState<ApolloClient<object>>();

  const [fileUploadGraphQLClient, setFileUploadGraphQLClient] =
    useState<ApolloClient<object>>();

  useEffect(() => {
    if (!accessToken || !identityClaims) {
      setAuthenticatedGraphQLClient(undefined);
      setFileUploadGraphQLClient(undefined);
      return;
    }
    const httpLink = createHttpLink({
      uri: `${graphqlUrl}`,
    });

    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${accessToken}`,
        },
      };
    });

    const client = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache(),
    });

    setAuthenticatedGraphQLClient(client);

    const fileUploadLink = createUploadLink({
      uri: `${graphqlUrl}`,
    });

    const fileUploadClient = new ApolloClient({
      link: authLink.concat(fileUploadLink as any),
      cache: new InMemoryCache(),
    });

    setFileUploadGraphQLClient(fileUploadClient);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, identityClaims]);

  const value = useMemo(
    () => ({
      graphQLClient,
      authenticatedGraphQLClient,
      fileUploadGraphQLClient,
    }),
    [graphQLClient, authenticatedGraphQLClient, fileUploadGraphQLClient]
  );
  return (
    <GraphQLContext.Provider value={value}>{children}</GraphQLContext.Provider>
  );
};

export default GraphQLProvider;
