"use client";

import { createContext, ReactNode } from "react";
import { MessageInstance } from "antd/lib/message/interface";
import { NotificationInstance } from "antd/lib/notification/interface";

interface IMessageContext {
  messageApi: MessageInstance;
  contextHolder: ReactNode;
  notificationApi: NotificationInstance;
  contextHolderNotification: ReactNode;
}

const MessageContext = createContext<IMessageContext>({
  messageApi: {} as MessageInstance,
  contextHolder: null,
  notificationApi: {} as NotificationInstance,
  contextHolderNotification: null,
});

export default MessageContext;
