"use client";

import React, { ReactNode, useMemo } from "react";
import { message, notification } from "antd";

import MessageContext from "./MessageContext";
import useMessage from "./useMessage";

const MessageChild = ({ children }: { children: ReactNode }) => {
  const { contextHolder, contextHolderNotification } = useMessage();

  return (
    <>
      {contextHolder}
      {contextHolderNotification}
      {children}
    </>
  );
};
const MessageProvider = ({ children }: { children: ReactNode }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [notificationApi, contextHolderNotification] =
    notification.useNotification();

  const value = useMemo(
    () => ({
      messageApi,
      contextHolder,
      notificationApi,
      contextHolderNotification,
    }),
    [messageApi, contextHolder, notificationApi, contextHolderNotification]
  );
  return (
    <MessageContext.Provider value={value}>
      <MessageChild>{children}</MessageChild>
    </MessageContext.Provider>
  );
};

export default MessageProvider;
