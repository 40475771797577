"use client";

import { createContext } from "react";
import { ApolloClient } from "@apollo/client";

interface IGraphQLContext {
  graphQLClient: ApolloClient<object>;
  authenticatedGraphQLClient?: ApolloClient<object>;
  fileUploadGraphQLClient?: ApolloClient<object>;
}

const GraphQLContext = createContext<IGraphQLContext>({
  graphQLClient: {} as ApolloClient<object>,
});

export default GraphQLContext;
